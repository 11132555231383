// Packages
import { SessionProvider } from 'next-auth/react'
import { appWithTranslation } from 'next-i18next'
import { ThemeProvider, useTheme } from 'next-themes'
import NextNProgress from 'nextjs-progressbar'
import Modal from 'react-modal'
import { ToastContainer, type Theme } from 'react-toastify'

// Types
import { type AppPropsWithLayout } from '@/types/app'
import type { ReactNode } from 'react'

// Context
import { GlobalContextProvider } from '@/context/GlobalContext'

// Styles
import '@/styles/globals.css'
import 'react-toastify/dist/ReactToastify.css'

// Attaching the modal to the root element
Modal.setAppElement('#__next')

function MyApp({ Component, pageProps, router, session }: AppPropsWithLayout): ReactNode {
  const getLayout = Component.getLayout ?? ((page) => page)
  const { resolvedTheme } = useTheme()

  return (
    <div className="mx-auto flex h-auto min-h-full w-full max-w-[100ch] flex-col items-center pt-12 font-Montserrat">
      <ToastContainer
        position={'top-center'}
        autoClose={2500}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        draggable={false}
        theme={resolvedTheme as Theme}
      />
      <NextNProgress />
      <SessionProvider session={session}>
        <ThemeProvider
          enableSystem={false}
          attribute="class"
        >
          <GlobalContextProvider>{getLayout(<Component {...pageProps} />)}</GlobalContextProvider>
        </ThemeProvider>
      </SessionProvider>
    </div>
  )
}

export default appWithTranslation(MyApp)
